<template>
  <div class="xw-content-detail">
    <div class="main" v-if="contentDetail">
      <div class="title">{{ contentDetail.name }}</div>
      <div v-html="contentDetail.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "xw-content-detail",
  data() {
    return {
      id: null,
      contentDetail: null,
    };
  },
  created() {
    document.title = "小文老师";
    this.id = this.$router.history.current.query.id;
    this.getContentDetail();
  },
  methods: {
    getContentDetail() {
      this.axios
        .get("/api/common/imageText/anon/detail", {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          console.log(res);
          this.contentDetail = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.xw-content-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
  box-sizing: border-box;
  .main {
    box-sizing: border-box;
    width: 768px;
    background-color: #fff;
    padding: 20px;
    min-height: 100vh;
    .title {
      text-align: center;
      padding: 0px 10px 20px;
      font-size: 15px;
      font-weight: 500;
    }
  }
}
@media screen and (max-width: 900px) {
  .xw-content-detail {
    .main {
      width: 100%;
    }
  }
}
</style>